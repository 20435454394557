<template>
  <div class="col-sm-12 ml-3">
    <div class="row mb-3">
      <div class="col-sm-11 border border-light py-2">
        <div class="row">
          <div class="col-sm-2 pr-1">
            <multiselect
              v-model="pisoSalaSelected"
              :options="listaPisoSala"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Piso/Cama"
              :searchable="true">
              <span slot="caret" v-if="!(pisoSalaSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-2">
            <multiselect
              v-model="camaSelected"
              :options="listaCamasFiltered"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Cama"
              :searchable="true">
              <span slot="caret" v-if="!(camaSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-2 px-0 ">
            <multiselect
              v-model="claseSelected"
              :options="listaClases"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Clase"
              :searchable="true">
              <span slot="caret" v-if="!(claseSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-1 pt-1">
            <vue-numeric
              v-model="importe"
              class="form-control"
              separator=","
              :precision="2"
              placeholder="Importe"
            />
          </div>
          <div class="col-sm-2 pl-0">
            <multiselect
              v-model="tipoInternacionSelected"
              :options="tipoInternacion"
              placeholder="Tipo"
              label="nombre"
              track-by="id"
              select-label="" deselect-label="X"
              :disabled="!isMultiselectDisabled"
            >
            </multiselect>
          </div>
          <div class="col-sm-2 px-0">
            <multiselect
                v-model="selectedOtherOption"
                :options="otherOptions"
                placeholder="Estado"
                track-by="id"
                label="nombre"
                select-label="" deselect-label="X"
              ></multiselect>
          </div>
          <div class="col-sm-1 pl-1 pr-1">
            <button
              class="btn btn-success btn-block px-1"
              @click="agregarModificarItem"
              :disabled="inputDisabled"
            >
              {{textButtonForm}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-11 px-0">
        <b-table
          class="table table-striped table-hover table-bordered"
          :busy="isLoading"
          :fields="fields"
          :items="listaSalaCama"
          thead-class="bg-info text-white text-center"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template v-slot:head(tipo_tratamiento)>
            <div class="row d-flex align-items-center m-0 p-0">
              <div class="col-2 p-0">
                <button
                  v-b-modal.tipoInternacion
                  type="button"
                  class="btn btn-success text-center mr-2"
                  style="width: 30px; height: 30px; display: grid; align-items: center; justify-content: center;"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                    />
                  </svg>
                </button>
              </div>
              <div class="col p-0 text-center">
                <span>Tipo</span>
              </div>
            </div>
          </template>

          <template #cell(editar)="items">
            <i
              @click="items.item.estado_cama !== 'Ocupado' && editarItem(items.item)"
              :class="['nav-icon i-Pen-5 font-weight-bold', items.item.estado_cama !== 'Ocupado' ? 'text-success' : 'text-secondary']"
              :style="{ cursor: items.item.estado_cama !== 'Ocupado' ? 'pointer' : 'default' }"
            ></i>
          </template>
          <template #cell(eliminar)="items">
            <i
              @click="onClickEliminarIcon(items.item)"
              v-b-modal.modal-eliminar
              class="nav-icon i-Close-Window text-danger font-weight-bold"
              style="cursor: pointer"
            ></i>
          </template>
          <template #cell(importe_x_dia)="items">
            {{items.item.importe_x_dia | numeral('0,0.00')}} {{simboloMonedaPrincipal}}
          </template>
          <template #cell(estado_cama)="items">
            <span
              :class="{
                'text-danger font-weight-bold': items.item.estado_cama === 'Ocupado',
                'text-secondary': items.item.estado_cama !== 'Ocupado'
              }"
            >
              {{ items.item.estado_cama }}
            </span>
          </template>
        </b-table>
        <b-modal id="modal-eliminar"
          :title="'Seguro que quieres eliminar el Registro - N° '+selectedNro+'?'"
          hide-footer
        >
          <div class="row">
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                Eliminar
              </button>
            </div>
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-outline-dark"
              @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
            </div>
          </div>
        </b-modal>
      </div>
      <div class="col-sm-6 px-0">
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-2">
              <div class="col-sm-8 ml-3">
              </div>
              <div class="col-sm-2 ml-1">
                <b-modal id="tipoInternacion" title="Tipo De Tratamiento" hide-footer>
                  <form v-on:submit.prevent="isEditing ? actualizarCategoria() : agregarCategoria()" autocomplete="off">
                    <div class="modal-body">
                      <div class="row form-group">
                        <div class="col-sm-9 pr-1">
                          <input
                            id="nombre1"
                            type="text"
                            class="form-control"
                            placeholder="Ingrese"
                            required
                            v-model="servicio"
                          />
                        </div>
                        <div class="col-sm-3 pl-1">
                          <button class="btn btn-warning" type="submit">
                            {{ isEditing ? 'Editar' : 'Agregar' }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="modal-body pt-0">
                    <div class="row">
                      <div class="col-sm-12">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <td width="10%"></td>
                              <td>Nombre</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="categoria in categorias" :key="categoria.id" @click="seleccionarCategoria(categoria)">
                              <td>
                                <i
                                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Eliminar Categoria"
                                  style="cursor: pointer"
                                  @click="eliminarCategoria(categoria)"
                                ></i>
                              </td>
                              <td>{{ categoria.nombre }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
            </div>
        </div>
      </div>

    </div>
  <modal-confirmacion
  :modalShow="modalShow"
  :message="modalMessage"
  :existeEnLista="existeEnLista"
  @confirm="deleteCategoriaServicio"
  @cancel="modalShow = false"/>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapState, mapActions, mapMutations } from 'vuex';
import modalConfirmacion from './theModalConfirmacion.vue';

export default {
  name: 'SalaCama',
  components: {
    Multiselect,
    VueNumeric,
    modalConfirmacion,
  },
  props: {
    isMultiselectDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    listaSalaCama() {
      this.updateComboCama();
    },
    servicio(newValue) {
      if (!newValue) {
        this.isEditing = false;
      }
    },
  },
  data() {
    return {
      pisoSalaSelected: null,
      listaPisoSala: [],
      camaSelected: null,
      listaCamas: [],
      listaCamasFiltered: [],
      claseSelected: null,
      listaClases: [],
      inputDisabled: false,
      textButtonForm: 'Agregar',
      isLoading: true,
      fields: [
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'ID', thStyle: { width: '3%' },
        },
        {
          key: 'piso_sala_nombre', label: 'Piso/Sala', thStyle: { width: '18%' },
        },
        {
          key: 'cama_nombre', label: 'Cama', thStyle: { width: '13%' },
        },
        {
          key: 'clase_nombre', label: 'Clase', thStyle: { width: '13%' },
        },
        {
          key: 'importe_x_dia', class: 'text-center', label: 'Importe por Día', thStyle: { width: '13%' },
        },
        {
          key: 'tipo_tratamiento', label: 'Tipo', class: 'text-center', thStyle: { width: '16%' },
        },
        {
          key: 'estado_cama', label: 'Estado', class: 'text-center', thStyle: { width: '8%' },
        },
      ],
      listaSalaCama: [],
      modoEdicion: false,
      selectedId: null,
      selectedNro: null,
      simboloMonedaPrincipal: '',
      importe: 0,
      selectedStatus: null,
      selectedOtherOption: null,
      otherOptions: [
      ],
      servicio: '',
      categorias: [
      ],
      tipoInternacion: [],
      tipoInternacionSelected: null,
      tipoInternacionDosSelected: null,
      modalShow: false,
      modalMessage: '',
      confirmDelete: null,
      existeEnLista: false,
      isEditing: false,
    };
  },
  async created() {
    await this.cargarDatosIniciales();
    this.listarSalaCamas();
  },
  methods: {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/parametros/sala_camas/create');
        this.listaPisoSala = RESPONSE.data.data.piso_salas;
        this.listaCamas = RESPONSE.data.data.camas;
        this.listaClases = RESPONSE.data.data.clases;
        this.setHoraSalida(RESPONSE.data.data.hora_salida ?? '');
        this.simboloMonedaPrincipal = RESPONSE.data.data.moneda_principal.simbolo;
        this.otherOptions = RESPONSE.data.data.estados_cama;
        this.categorias = RESPONSE.data.data.tipo_tratamiento;
        this.listarTipoTratamiento();
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async listarTipoTratamiento() {
      try {
        const RESPONSE = await axios.get('/clinic/parametros/tipo_tratamiento');
        this.categorias = RESPONSE.data.data.tipo_tratamiento;
        this.tipoInternacion = this.categorias;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async listarSalaCamas() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/parametros/sala_camas');
        this.listaSalaCama = RESPONSE.data.data;
        this.listaSalaCama.sort((a, b) => b.id - a.id);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    updateComboCama() {
      let listaTemporal = [...this.listaCamas];
      this.listaSalaCama.forEach((item) => {
        listaTemporal = listaTemporal.filter((el) => el.id !== item.cama_id);
      });
      this.listaCamasFiltered = listaTemporal;
    },
    updateComboCamaFromEditar() {
      if (this.modoEdicion === true) {
        let listaTemporal = [...this.listaCamas];
        this.listaSalaCama.forEach((item) => {
          if (item.id === this.selectedId) {
            listaTemporal = listaTemporal.filter((el) => (
              el.id !== item.cama_id || this.selectedId === item.id
            ));
          } else {
            listaTemporal = listaTemporal.filter((el) => el.id !== item.cama_id);
          }
        });
        this.listaCamasFiltered = listaTemporal;
      }
    },
    async guardarDatos() {
      const REQUEST = {
        piso_sala_id: this.pisoSalaSelected?.id || null,
        cama_id: this.camaSelected?.id || null,
        clase_id: this.claseSelected?.id || null,
        importe_x_dia: this.importe,
        estado_cama_id: this.selectedOtherOption?.id || null,
        tipo_tratamiento_id: this.tipoInternacionSelected?.id || null,
      };
      let estado = false;
      try {
        const RESPONSE = await axios.post('/clinic/parametros/sala_camas', REQUEST);
        await this.listarSalaCamas();
        util.showNotify(RESPONSE.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
    async guardarTipoTratamiento(nuevaCategoria) {
      const REQUEST = {
        nombre: nuevaCategoria.nombre,
      };
      try {
        const RESPONSE = await axios.post('/clinic/parametros/tipo_tratamiento/store', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      this.listarTipoTratamiento();
    },
    async actualizarItem() {
      const REQUEST = {
        piso_sala_id: this.pisoSalaSelected.id,
        cama_id: this.camaSelected.id,
        clase_id: this.claseSelected.id,
        importe_x_dia: this.importe,
        estado_cama_id: this.selectedOtherOption.id,
        tipo_tratamiento_id: this.tipoInternacionSelected?.id ? this.tipoInternacionSelected.id : null,
      };
      let estado = false;
      try {
        const URL = `/clinic/parametros/sala_camas/${this.selectedId}`;
        const response = await axios.put(URL, REQUEST);
        await this.listarSalaCamas();
        util.showNotify(response.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
    activarModoEdicion() {
      this.modoEdicion = true;
      this.textButtonForm = 'Modificar';
    },
    desactivarModoEdicion() {
      this.modoEdicion = false;
      this.textButtonForm = 'Agregar';
    },
    agregarModificarItem() {
      if (this.pisoSalaSelected === null) {
        util.showNotify('Elije un Piso/Sala.', 'warn');
        return;
      }
      if (this.camaSelected === null) {
        util.showNotify('Elije una Cama.', 'warn');
        return;
      }
      if (this.claseSelected === null) {
        util.showNotify('Elije una Clase.', 'warn');
        return;
      }
      if (this.selectedOtherOption === null) {
        util.showNotify('Elije un Estado.', 'warn');
        return;
      }
      this.inputDisabled = true;
      if (this.modoEdicion) {
        if (this.actualizarItem()) {
          this.pisoSalaSelected = null;
          this.camaSelected = null;
          this.claseSelected = null;
          this.importe = 0;
          this.selectedId = null;
          this.selectedOtherOption = null;
          this.tipoInternacionSelected = null;
          this.desactivarModoEdicion();
        }
      } else {
        this.selectedId = null;
        this.selectedNro = null;
        if (this.guardarDatos()) {
          this.pisoSalaSelected = null;
          this.camaSelected = null;
          this.claseSelected = null;
          this.importe = 0;
          this.selectedOtherOption = null;
          this.tipoInternacionSelected = null;
        }
      }
      this.inputDisabled = false;
    },
    cargarComboPisoSalaById(id) {
      if (id === null) {
        return;
      }
      this.pisoSalaSelected = this.listaPisoSala.find((el) => el.id === id);
    },
    cargarComboCamaById(id) {
      if (id === null) {
        return;
      }
      this.camaSelected = this.listaCamas.find((el) => el.id === id);
    },
    cargarComboClaseById(id) {
      if (id === null) {
        return;
      }
      this.claseSelected = this.listaClases.find((el) => el.id === id);
    },
    cargarComboEstadoById(id) {
      if (id === null) {
        return;
      }
      this.selectedOtherOption = this.otherOptions.find((el) => el.id === id);
    },
    cargarComboTipoTratamientoById(id) {
      if (id === null) {
        return;
      }
      this.tipoInternacionSelected = this.categorias.find((el) => el.id === id);
    },
    async getDataToEdit() {
      try {
        const RESPONSE = await axios.get(`/clinic/parametros/sala_camas/${this.selectedId}/edit`);
        const PISO_SALA_ID = RESPONSE.data.data.piso_sala_id;
        const CAMA_ID = RESPONSE.data.data.cama_id;
        const CLASE_ID = RESPONSE.data.data.clase_id;
        this.importe = RESPONSE.data.data.importe_x_dia;
        const ESTADO = RESPONSE.data.data.estado_cama_id;
        const TIPO_TRAT = RESPONSE.data.data.tipo_tratamiento_id;
        this.cargarComboPisoSalaById(PISO_SALA_ID);
        this.cargarComboCamaById(CAMA_ID);
        this.cargarComboClaseById(CLASE_ID);
        this.cargarComboEstadoById(ESTADO);
        this.cargarComboTipoTratamientoById(TIPO_TRAT);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    editarItem(item) {
      this.activarModoEdicion();
      this.selectedId = item.id;
      this.selectedNro = null;
      this.getDataToEdit();
      this.updateComboCamaFromEditar();
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    onClickEliminarIcon(item) {
      if (this.modoEdicion) {
        this.pisoSalaSelected = null;
        this.camaSelected = null;
        this.claseSelected = null;
        this.importe = 0;
        this.updateComboCama();
        this.desactivarModoEdicion();
      }
      this.setItems(item);
    },
    eliminarRegistroLocalmente() {
      this.listaSalaCama = this.listaSalaCama.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.eliminarRegistroLocalmente();
        const RESPONSE = await axios.delete(`/clinic/parametros/sala_camas/${this.selectedId}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarSalaCamas();
      }
    },
    agregarCategoria() {
      if (this.servicio.trim() !== '') {
        const nuevaCategoria = {
          nombre: this.servicio,
        };
        this.categorias.push(nuevaCategoria);
        this.guardarTipoTratamiento(nuevaCategoria);
        this.isEditing = false;
        this.servicio = '';
      }
    },
    async actualizarCategoria() {
      try {
        const REQUEST = {
          nombre: this.servicio,
        };
        const RESPONSE = await axios.put(`/clinic/parametros/tipo_tratamiento/update/${this.selectedCategoria.id}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.listarTipoTratamiento();
        this.listarSalaCamas();
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      this.isEditing = false;
      this.servicio = '';
    },
    seleccionarCategoria(categoria) {
      this.selectedCategoria = categoria;
      this.servicio = categoria.nombre;
      this.isEditing = true;
    },
    eliminarCategoria(categoria) {
      this.selectedCategoria = categoria;
      this.existeEnLista = this.listaSalaCama.some((item) => item.tipo_tratamiento_id === categoria.id);
      if (this.existeEnLista) {
        this.modalMessage = 'No se puede eliminar esta categoría porque está asociada a una sala/cama.';
        this.modalShow = true;
      } else {
        this.modalMessage = '¿Estás seguro de que deseas eliminar esta categoría?';
        this.modalShow = true;
      }
    },
    async deleteCategoriaServicio() {
      try {
        const RESPONSE = await axios.delete(`/clinic/parametros/tipo_tratamiento/delete/${this.selectedCategoria.id}`);
        util.showNotify(RESPONSE.data.message, 'success');
        this.categorias = this.categorias.filter((item) => item.id !== this.selectedCategoria.id);
      } catch (error) {
        util.showNotify('Error al eliminar la categoría', 'error');
      }
      this.modalShow = false;
      this.tipoInternacion = this.categorias;
    },
    ...mapActions('salaCama', [
      'saveHoraSalida',
    ]),
    ...mapMutations('salaCama', [
      'setHoraSalida',
    ]),
  },
  computed: {
    ...mapState('salaCama', [
      'horaSalida',
    ]),
    horaSalidaComputed: {
      get() {
        return this.horaSalida;
      },
      set(value) {
        this.setHoraSalida(value);
      },
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
